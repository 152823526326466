.custom-datepicker{
    border: 0.5px solid lightgrey;
    border-radius: 5px;
    text-align: center;
    width: 100%;
}


.custom-datepicker::before{
    content: "test";
}