@media all and (max-width: 1600px) and (min-width: 768px) {
  body *:not(.font-unset,.font-unset,.font-unset *, .navbar-icon,.no-fontset) {
    font-size: small !important;
    
  }
}

@media all and (max-width: 2000px) and (min-width: 1601px) {
  body *:not(.font-unset,.font-unset,.font-unset *, .navbar-icon,.no-fontset) {
    font-size: medium !important;
  }
}

.navbar-icon{
  font-size: 1.2rem !important;
}

.font-unset{
  font-size: 1.7rem  !important;
}

.premium-sidenav{

  transition: 0.7s;
}

.premium-sidenav:hover{
  background-color: gold !important;
  box-shadow: 0px 0px 3px 1px white !important;
  /* box-shadow: 0px 0px 3px 2px white !important; */
  
}

.premium-sidenav *:not(.sidenav-item-icon,.sidenav-item-icon *){
  color:white !important;
  text-shadow: 0 0 5px black;
}

.success-button{
  color: blue !important;
  font-size: small !important;
  background-color: gold !important;
  margin:0.1rem 0.5rem ;
  padding: 0.1rem 0.5rem;
  border-radius: 10px;
  box-shadow:  3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}


.truncate {
  width: "100%";
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}


#simple-popover > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-ty7lqz-MuiPaper-root-MuiPopover-paper{
  overflow: visible;
}

#simple-popover > div.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1okeesj{
  overflow: visible
}